import { FlexWrapContainer } from '@lib/flex-wrap-container';
import { Box, Text } from '@sanch941/lib';
import React from 'react';
import { Card } from './card';
import first from '@assets/images/blog/mobile/1.png';

export const Section = () => {
    return (
        <>
            <Text fz={24} lh={28} $color="#470BBE">
                Последние:
            </Text>

            <Box $top={10}>
                <FlexWrapContainer gap="3%" sm={{ gap: '1%' }}>
                    <Card
                        mobileImg={first}
                        category="Категория статьи или темы"
                        shortDescription="Статья про деньги и что-то там еще"
                    />
                    <Card
                        mobileImg={first}
                        category="Категория статьи или темы"
                        shortDescription="Статья про деньги и что-то там еще"
                    />
                    <Card
                        mobileImg={first}
                        category="Категория статьи или темы"
                        shortDescription="Статья про деньги и что-то там еще"
                    />
                </FlexWrapContainer>
            </Box>
        </>
    );
};
