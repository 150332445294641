import { Box, pxRem, Text } from '@sanch941/lib';
import React from 'react';
import styled from 'styled-components';

export const WelcomeTemplate = () => {
    return (
        <Box $top={26} $bottom={30} $height={239} $width="100%" bg="#F8F6FB">
            <StyledText>
                Статья про <br /> маркетинг и <br /> что-то там еще
            </StyledText>
        </Box>
    );
};

const StyledText = styled(Text).attrs({
    fz: 32,
    lh: 38,
    fw: 900
})`
    position: absolute;
    bottom: ${pxRem(30)};
    left: ${pxRem(13)};
`;
