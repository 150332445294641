import { Box, media, pxRem, Text } from '@sanch941/lib';
import { Container, DynamicBg } from '@ui';
import React from 'react';
import styled from 'styled-components';
import article from '@assets/images/blog/article.png';
import articleDesktop from '@assets/images/blog/article-desktop.png';

export const ArticlesTemplate = () => {
    return (
        <>
            <Container>
                <Text
                    fw={700}
                    fz={32}
                    lh={38}
                    $color="#470BBE"
                    sm={{ fz: 36, lh: 42 }}
                >
                    Статьи
                </Text>
            </Container>

            <Container mobilePadding={false}>
                <Box $top={20} sm={{ $flex: true, $top: 30 }}>
                    <StyledGrayContainer>
                        <div>
                            <Text fz={14} $color="#470BBE">
                                Категория статьи или темы
                            </Text>

                            <Box $flex $top={15}>
                                <StyledLine />

                                <Text fz={24} lh={28}>
                                    Статья про маркетинг и что-то там еще
                                </Text>
                            </Box>
                        </div>
                    </StyledGrayContainer>

                    <StyledBgContainer
                        $height={230}
                        $width="100%"
                        sm={{ $height: 400, $width: '50%' }}
                    >
                        <DynamicBg mobile={article} desktop={articleDesktop} />
                    </StyledBgContainer>
                </Box>
            </Container>
        </>
    );
};

const StyledGrayContainer = styled.div`
    background-color: #f7f7f7;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: ${pxRem(60)} ${pxRem(13)};

    ${media.md} {
        width: 50%;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 10px;
        order: 2;
        padding: 0 ${pxRem(51)};
        display: flex;
        align-items: center;
    }
`;

const StyledBgContainer = styled(Box)`
    ${media.md} {
        order: 1;

        ${DynamicBg} {
            border-radius: 10px;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }
`;

const StyledLine = styled.div`
    height: ${pxRem(26)};
    width: ${pxRem(4)};
    background-color: #470bbe;
    border-radius: 5px;
    margin-right: ${pxRem(10)};
`;
