import { Box, Text } from '@sanch941/lib';
import { DynamicBg } from '@ui';
import React, { FC } from 'react';
import styled from 'styled-components';

export const Card: FC<ComponentProps> = ({
    mobileImg,
    desktopImg,
    category,
    shortDescription
}) => {
    return (
        <Box
            $top={20}
            $left="3%"
            $width="47%"
            sm={{ $width: '31%', $left: '1%' }}
        >
            <Box $height={147} $width="100%" sm={{ $height: 336 }}>
                <StyledDynamicBg mobile={mobileImg} desktop={desktopImg} />
            </Box>

            <Box $top={5} $bottom={3} sm={{ $top: 15, $bottom: 15 }}>
                <Text fz={10} lh={12} $color="#470BBE" sm={{ fz: 14, lh: 16 }}>
                    {category}
                </Text>
            </Box>

            <Text fz={14} lh={16} sm={{ fz: 24, lh: 28 }}>
                {shortDescription}
            </Text>
        </Box>
    );
};

interface ComponentProps {
    mobileImg?: string;
    desktopImg?: string;
    category: string;
    shortDescription: string;
}
export type CardProps = ComponentProps;

const StyledDynamicBg = styled(DynamicBg)`
    border-radius: 5px;
`;
