import React from 'react';
import { ArticlesTemplate } from './templates/articles';
import { SectionArticlesTemplate } from './templates/section-articles/section-articles';
import { WelcomeTemplate } from './templates/welcome';

export const BlogPage = () => {
    return (
        <>
            <WelcomeTemplate />
            <ArticlesTemplate />
            <SectionArticlesTemplate />
        </>
    );
};
