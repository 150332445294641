import { Box } from '@sanch941/lib';
import { Container } from '@ui';
import React from 'react';
import { Section } from './section';

export const SectionArticlesTemplate = () => {
    return (
        <Container>
            <Box $bottom={30}>
                <Box $top={40}>
                    <Section />
                </Box>
            </Box>
        </Container>
    );
};
